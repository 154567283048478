<template>

</template>
<script>
export default {
    created(){
        // 来源监测-推特引流注册
        // localStorage.setItem('isTwitter',1)
        this.$store.commit('app/SET_IS_TWITTER',1)
        this.$router.push({
            path:'/light'
        })
    }
}
</script>